import React from 'react';
import './PersonalityTraits.scss';
import { personalityData } from '../../Data/Data';

function PersonalityTraits() {
  return (
    <div className='personality'>
      <ul className='personality-traits'>
        {personalityData.map((trait) => (
          <li className='personality-skill' key={trait.id}>
            {trait.icon}
            {trait.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
export default PersonalityTraits;
