import './ProfileName.scss';
import React from 'react';

function ProfileName() {
  return (
    <div className='wrapper'>
      <span className='name' data-text='Nedeljko'></span>
      <span className='surname' data-text='Popović'></span>
    </div>
  );
}

export default ProfileName;
