import React, { useState } from 'react';
import { Map, Marker, Overlay } from 'pigeon-maps';
import './Map.scss';

function MyMap() {
  // const [position, setPosition] = useState({ lat: null, lng: null });
  const [center, setCenter] = useState([44.8234288, 20.4100497]);
  const [zoom, setZoom] = useState(14);

  // navigator.geolocation.getCurrentPosition((position) => {
  //   setPosition({
  //     lat: position.coords.latitude,
  //     lng: position.coords.longitude,
  //   });
  // });

  // if (!position.lat || !position.lng) return;

  return (
    <div className='map'>
      <Map
        center={center}
        zoom={zoom}
        onBoundsChanged={({ center, zoom }) => {
          setCenter(center);
          setZoom(zoom);
        }}
      >
        <Marker width={50} anchor={[44.8228288, 20.4030997]} />
        <Overlay />
      </Map>
    </div>
  );
}

export default MyMap;
