import React from "react";
import { Link } from "react-router-dom";
import { MdPersonPin, MdAttachEmail } from "react-icons/md";
import { BsPhoneFill } from "react-icons/bs";
import { TbMailForward } from "react-icons/tb";
import "./Info.scss";
import ButtonLink from "../../../Buttons/ButtonLink";

function Info() {
  return (
    <div className="contact-info">
      <div className="contact-info--container">
        <div className="contact-info_item">
          <MdPersonPin className="contact-info_item--icon" />
          Belgrade, Serbia
        </div>
        <div
          className="contact-info_item phone"
          onClick={() => (window.location = `tel:+381 63 8941407`)}
        >
          <BsPhoneFill className="contact-info_item--icon" />
          +381 63 8941407
        </div>
      </div>
      <div className="contact-info--container">
        <div className="contact-info_item">
          <MdAttachEmail className="contact-info_item--icon " />
          <Link
            className="email"
            onClick={() =>
              (window.location = "mailto:nedeljkopopovic94@gmail.com")
            }
          >
            nedeljkopopovic94@gmail.com
          </Link>
        </div>
        <ButtonLink
          text={"Message me"}
          icon={<TbMailForward className="btn__invisible--icon" />}
          click={() => (window.location = "mailto:nedeljkopopovic94@gmail.com")}
        />
      </div>
    </div>
  );
}

export default Info;
