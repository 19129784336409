import React from 'react';
import Component from './Component';

function ComponentBlock({ title, classDiv, classHeader, iconHeader, data }) {
  return (
    <div className={classDiv}>
      <div className={classHeader}>
        {iconHeader} <h2>{title}</h2>
        <span>.</span>
      </div>
      {data.map((data) => {
        return (
          <Component
            key={data.id}
            title={data.title}
            place={data.place}
            date={data.date}
            city={data.city}
            info={data.info}
          />
        );
      })}
    </div>
  );
}

export default ComponentBlock;
