import './SkillComponenet.scss';

import React from 'react';

function SkillComponent({ skill, name }) {
  return (
    <div className='skill'>
      <div className='skill--icon'>{skill}</div>
      <div className='skill--name'>{name}</div>
    </div>
  );
}

export default SkillComponent;
