import './ButtonGit.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context';

function ButtonGit({ git, text, icon }) {
  const { openInNewTab } = useGlobalContext();

  return (
    <Link className='buttonGit' onClick={() => openInNewTab(git)} role='button'>
      <span>{text}</span>
      <div className='icon'>{icon}</div>
    </Link>
  );
}

export default ButtonGit;
