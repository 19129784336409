import React from 'react';
import '../Hobbies.scss';

function HobbyContainer({ icon, name }) {
  return (
    <div className='hobbies-item'>
      <div className='hobbies-icons'>{icon}</div>
      <p className='hobbies-name'>{name}</p>
    </div>
  );
}

export default HobbyContainer;
