import React from "react";
import "./AboutMe.scss";
import { useGlobalContext } from "../../../context";
import PersonalityTraits from "./PersonalityTraits/PersonalityTraits";
import Hobbies from "./Hobbies/Hobbies";
import { aboutMeInfo } from "../Data/Data";

function AboutMe() {
  const { showContent, start } = useGlobalContext();

  return (
    <div
      className={`card
      ${start ? "card--active-start" : "card--active"}
      `}
    >
      <div
        className="about_me"
        style={{ display: showContent ? "flex" : "none" }}
      >
        <h1 className="header">
          about me<span>.</span>
        </h1>
        <h4 className="subtitle">
          Belgrade <span> / </span> Frontend devoleper
        </h4>
        <div className="info">
          <p>{aboutMeInfo}</p>
          <PersonalityTraits />
        </div>
        <Hobbies />
      </div>
    </div>
  );
}

export default AboutMe;
