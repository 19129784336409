import React from 'react';
import './LinkComponent.scss';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../../../../context';

function LinkComponent({ link, name, icon }) {
  const { openInNewTab } = useGlobalContext();

  return (
    <li className='link'>
      <Link onClick={() => openInNewTab(link)} className={name}>
        {icon}
      </Link>
    </li>
  );
}

export default LinkComponent;
