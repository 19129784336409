import Card from './components/Cards/Card';
import Loading from './components/Loading/Loading';
import Profile from './components/Profile/Profile';
import Sidebar from './components/Sidebar/Sidebar';
import { useGlobalContext } from './context';

import './sass/App.scss';

function App() {
  const { isLoading } = useGlobalContext();

  return (
    <div className='container'>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Sidebar />
          <Profile />
          <Card />
        </>
      )}
    </div>
  );
}

export default App;
