import './Component.scss';

import React from 'react';

function Component({ title, place, date, city, info }) {
  return (
    <div className='component'>
      <div className='component-date'>{date}</div>
      <h4 className='component-title'>{title}</h4>
      <div className='component-place'>{place}</div>
      <div className='component-city'>{city}</div>
      <div className='component-info'>{info}</div>
    </div>
  );
}

export default Component;
