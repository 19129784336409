import React from 'react';
import './Profile.scss';
import picture from '../../img/profileImg.png';
import { profileData } from '../Cards/Data/Data';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context';
import DownloadRoundBtn from '../../CV/DownloadRoundBtn';
import ProfileName from './ProfileName';

function Profile() {
  const { openInNewTab } = useGlobalContext();
  return (
    <div className='profile'>
      <img className='profile-picture' src={picture} alt='slika' />
      <ProfileName />
      <div className='profile-links'>
        <ul className='profile-links--list'>
          {profileData.map((link) => (
            <li key={link.id} className='profile-link'>
              <Link
                className='icon-container'
                onClick={() => openInNewTab(link.href)}
              >
                <span className='inner'></span>
                {link.icon}
              </Link>
            </li>
          ))}
          {/* download link */}
          <DownloadRoundBtn />
        </ul>
      </div>
    </div>
  );
}

export default Profile;
