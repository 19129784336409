import React from 'react';
import './Sidebar.scss';
import SidebarComponent from './SidebarComponent';
import Logo from './Logo/Logo';
import { sidebarData } from '../Cards/Data/Data';
import DownloadLink from '../../CV/DownloadLink';

function Sidebar() {
  return (
    <div className='sidebar'>
      <div className='sidebar-logo'>
        <Logo />
      </div>

      <ul className='side-nav'>
        {sidebarData.map((data, index) => (
          <SidebarComponent key={index} href={data.href} icon={data.icon} />
        ))}
        <DownloadLink />
      </ul>
      <div className='footer'>&copy; 2023 Nedeljko Popović</div>
    </div>
  );
}

export default Sidebar;
