import './ButtonBack.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context';
import { HiArrowLeft } from 'react-icons/hi';

function ButtonBack({ link, card }) {
  const { setActiveCard, setShowContent, start, setStart } = useGlobalContext();

  return (
    <div class='button-round'>
      <div className='button-round_container'>
        <Link
          className='button-round_content'
          to={link}
          onClick={() => {
            setActiveCard(card);
            setShowContent(false);
            if (start) setStart(false);
          }}
        >
          <span className='icon-back'>
            <HiArrowLeft className='button-round_icon' />
          </span>
        </Link>
      </div>
    </div>
  );
}

export default ButtonBack;
