import React from 'react';
import './Loading.scss';

function LoadingRing() {
  return (
    <div className='ring'>
      Loading
      <span className='load'></span>
    </div>
  );
}

export default LoadingRing;
