import React from "react";
import "./ExperienceAndEducation.scss";
import { useGlobalContext } from "../../../context";
import Skills from "./Skills/Skills";
import Languages from "./Languages/Languages";
import ComponentBlock from "./Components/ComponentBlock";

import { experienceInfo, componentBlockData } from "../Data/Data";

function ExperienceAndEducation() {
  const { showContent, start } = useGlobalContext();

  return (
    <div
      className={`card
    ${start ? "card--active-start" : "card--active"}
    `}
    >
      <div
        className="exp-edu"
        style={{ display: showContent ? "flex" : "none" }}
      >
        <div className="exp-edu--info">
          <h4 className="exp-edu--info_subtitle">
            30 months in frontend <span>/</span> 10+ projects <span>/</span>
            BSc Economics
          </h4>
          <p>{experienceInfo}</p>
        </div>
        <Skills />
        <div className="exp-edu--container">
          {componentBlockData.map((data) => (
            <ComponentBlock
              key={data.id}
              classDiv={data.classDiv}
              title={data.title}
              classHeader={data.classHeader}
              iconHeader={data.iconHeader}
              data={data.data}
            />
          ))}
        </div>
        <Languages />
      </div>
    </div>
  );
}

export default ExperienceAndEducation;
