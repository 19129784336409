import React from 'react';
import './Projects.scss';
import { useGlobalContext } from '../../../context';
import ProjectComponentBlock from './ProjectCmponents/ProjectComponentBlock';
import { projectContainerData } from '../Data/Data';

function Projects() {
  const { showContent, start } = useGlobalContext();

  return (
    <div
      className={`card
    ${start ? 'card--active-start' : 'card--active'}
    `}
    >
      <div
        className='projects'
        style={{ display: showContent ? 'flex' : 'none' }}
      >
        <h1 className='header'>
          projects<span>.</span>
        </h1>
        {projectContainerData.map((data) => (
          <div key={data.id} className='projects-container'>
            <h3 className='projects-container-header'>
              {data.icon}
              {data.name}
              <span>.</span>
            </h3>
            <ProjectComponentBlock projectData={data.projectData} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
