import React from "react";
import CV from "../CV/Nedeljko.Popovic_CV.pdf";
import { FaFilePdf } from "react-icons/fa";

function DownloadRoundBtn() {
  return (
    <li className="profile-link profile-link_download">
      <a className="icon-container icon-container_download" href={CV} download>
        <span className="inner"></span>

        <FaFilePdf className="profile-link-icon profile-link-icon_download" />
      </a>
    </li>
  );
}

export default DownloadRoundBtn;
