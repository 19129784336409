import React from 'react';
import { Link } from 'react-router-dom';
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { SiNetlify } from 'react-icons/si';
import './SocialNetvorks.scss';
import LinkComponent from './LinkComponent/LinkComponent';
import { socialNetData } from '../../Data/Data';

function SocialNetworks() {
  return (
    <div className='contact-social'>
      <div className='contact-social--title'>
        <h3>
          social<span>.</span>
        </h3>
      </div>
      <ul className='contact-social--links'>
        {socialNetData.map((socialNet, index) => (
          <LinkComponent
            key={index}
            name={socialNet.name}
            link={socialNet.link}
            icon={socialNet.icon}
          />
        ))}
      </ul>
    </div>
  );
}

export default SocialNetworks;
