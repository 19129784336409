import React from 'react';
import './Cards.scss';
import { Route, Routes, Navigate } from 'react-router-dom';
import AboutMe from './AboutMe/AboutMe';
import ExperienceAndEducation from './ExpirienceAndEducation/ExperienceAndEducation';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
import ProjectCard from './Projects/ProjectsCards/ProjectCard';
import { reactProjectsData, javaScriptProjectsData } from './Data/Data';

function Card() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Navigate replace to='/about_me' />} />
        <Route path='/about_me' element={<AboutMe />} />
        <Route
          path='/experience_education'
          element={<ExperienceAndEducation />}
        />
        <Route path='/projects' element={<Projects />} />
        <Route path='/contact' element={<Contact />} />

        {reactProjectsData.map((project) => (
          <Route
            key={project.id}
            path={`/${project.href}`}
            element={<ProjectCard />}
          />
        ))}
        {javaScriptProjectsData.map((project) => (
          <Route
            key={project.id}
            path={`/${project.href}`}
            element={<ProjectCard />}
          />
        ))}
      </Routes>
    </>
  );
}

export default Card;
