import React from 'react';

import './Logo.scss';
import LogoImg from '../../../img/logo/logo.png';

function Logo() {
  return <img className='logo-pic' src={LogoImg} alt='pic' />;
}

export default Logo;
