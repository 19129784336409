import React from "react";
import CV from "../../src/CV/Nedeljko.Popovic_CV.pdf";
import icon from "../../src/CV/cvicon.svg";

import { BsFiletypePdf } from "react-icons/bs";

function DownloadLink() {
  return (
    <li className={`side-nav__item  side-nav__item--download`}>
      <a href={CV} className="side-nav__link side-nav__link--download" download>
        {/* <img src={icon} className='side-nav__link--icon--download' alt='img' /> */}
        <BsFiletypePdf className="side-nav__link--icon--download" />
      </a>
    </li>
  );
}

export default DownloadLink;
