import React from 'react';
import { useLocation } from 'react-router-dom';
import './ProjectCard.scss';
import { useGlobalContext } from '../../../../context';
import { reactProjectsData, javaScriptProjectsData } from '../../Data/Data';
import ButtonBack from '../../../Buttons/ButtonBack';
import Button from '../../../Buttons/Button';
import ButtonGit from '../../../Buttons/ButtonGit';
import { BsGithub } from 'react-icons/bs';
import Logo from '../../../Sidebar/Logo/Logo';

function ProjectCard() {
  const { showContent, start } = useGlobalContext();
  const { pathname } = useLocation();
  const cardActive = pathname.slice(1);

  return (
    <div
      className={`card
    ${start ? 'card--active-start' : 'card--active'}
    `}
    >
      <div
        className='project-card'
        style={{ display: showContent ? 'flex' : 'none' }}
      >
        {[
          reactProjectsData.find((obj) => obj.href === cardActive) ||
            javaScriptProjectsData.find((obj) => obj.href === cardActive),
        ].map((project) => (
          <>
            <div className='project-card_container'>
              <div className='project-card_container-body'>
                {/* header */}
                <div className='project-card_container-header'>
                  <ButtonBack link={'/projects'} card={'projects'} />
                  <div className='project-card_container-header--title'>
                    <h2 className='project-card_container-header--title_main'>
                      {project.title}
                      <span>.</span>
                    </h2>
                    <h3 className='project-card_container-header--title_subtitle'>
                      {project.subtitle}
                      <span>.</span>
                    </h3>
                  </div>
                </div>
                {/* pictures container */}
                <div className='picture-container'>
                  {project.images.map((image) => (
                    <>
                      <div className='picture-container--img1'>
                        <img
                          className='images-img1'
                          src={image.img}
                          alt='img1'
                        />
                      </div>
                      <>
                        {image.imgPhone && (
                          <div className='picture-container--img2'>
                            <img
                              className='images-img2'
                              src={image.imgPhone}
                              alt='img-phone'
                            />
                          </div>
                        )}
                      </>
                    </>
                  ))}
                </div>

                {/* overview section */}
                <div className='project-card_container-overview'>
                  {/* butnon for app link */}
                  {project.link.map((link) => (
                    <div className=' project-card_container-overview--link'>
                      <Button text={link.text} link={link.url} />
                    </div>
                  ))}
                  {/* info */}
                  <div className='project-card_container-overview_info'>
                    {project.info.map((paragraph) => (
                      <p className='project-card_container-overview_info--pharagraph'>
                        {paragraph}
                      </p>
                    ))}
                  </div>

                  {/* check the code button */}
                  <div className='project-card_container-gitbtn'>
                    {project.git.map((git) => (
                      <ButtonGit
                        text={git.text}
                        git={git.link}
                        icon={<BsGithub className='icon-link' />}
                      />
                    ))}
                  </div>
                </div>
              </div>
              {/* page footer */}
              <footer className='project-card_container-footer'>
                <div className='project-card_container-footer--code'>
                  &copy; powered <span>by</span> {project.code}
                </div>
                <div className='project-card_container-footer--logo'>
                  <Logo />
                </div>
              </footer>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default ProjectCard;
