import './Button.scss';
import React from 'react';
import { useGlobalContext } from '../../context';

function Button({ text, link }) {
  const { openInNewTab } = useGlobalContext();
  return (
    <div className='button' onClick={() => openInNewTab(link)}>
      <h4>{text}</h4>
    </div>
  );
}

export default Button;
