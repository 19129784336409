import React from 'react';
import './Hobbies.scss';
import HobbyContainer from './HobbyContainer/HobbyContainer';
import { hobbiesData } from '../../Data/Data';

function Hobbies() {
  return (
    <div className='hobbies'>
      <h3 className='title'>
        hobbies<span>.</span>
      </h3>
      <div className='hobbies-block'>
        {hobbiesData.map((hobby, index) => (
          <HobbyContainer key={index} icon={hobby.icon} name={hobby.name} />
        ))}
      </div>
    </div>
  );
}

export default Hobbies;
