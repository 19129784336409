import React from 'react';
import './Skills.scss';
import SkillComponent from './SkillComponent';
import { IoIosSpeedometer } from 'react-icons/io';
import { skillsData } from '../../Data/Data';

function Skills() {
  return (
    <div className='skills'>
      <h3 className='skills-header'>
        <IoIosSpeedometer className='skills-header-icon' /> skills
        <span>.</span>
      </h3>
      <div className='skill-container'>
        {skillsData.map((skill) => (
          <SkillComponent key={skill.id} name={skill.name} skill={skill.icon} />
        ))}
      </div>
    </div>
  );
}

export default Skills;
