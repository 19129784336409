import React from "react";

//sidebar icons
import { FaFileCode } from "react-icons/fa";
import { GrContact } from "react-icons/gr";
import { GiGraduateCap } from "react-icons/gi";
import { MdWorkHistory } from "react-icons/md";
/////za about me odaberi
import { BsPersonCircle } from "react-icons/bs";
import { IoMdFingerPrint } from "react-icons/io";

//personality icons
import { FaRegHandshake } from "react-icons/fa";
import { TiGroupOutline } from "react-icons/ti";
import { MdOutlineManageAccounts, MdOutlineRocketLaunch } from "react-icons/md";
import {
  GiPublicSpeaker,
  GiLightBulb,
  GiPencilBrush,
  GiStairsGoal,
  GiChameleonGlyph,
  GiBullseye,
} from "react-icons/gi";

//hobbies icons
import { BsFillCpuFill } from "react-icons/bs";
import { MdPsychology } from "react-icons/md";
import { FaBook, FaGlobe } from "react-icons/fa";
import { GrGamepad } from "react-icons/gr";
import { BiMoviePlay } from "react-icons/bi";
import { GiBodyBalance } from "react-icons/gi";
import { AiOutlineAreaChart } from "react-icons/ai";

//experience and education icons
import { IoIosSchool } from "react-icons/io";
import { RiBriefcase4Line } from "react-icons/ri";

//skills icons
import {
  SiHtml5,
  SiCss3,
  SiJavascript,
  SiReact,
  SiSap,
  SiMicrosoftexcel,
  SiNodedotjs,
  SiTypescript,
} from "react-icons/si";
import { TbSql } from "react-icons/tb";
import { BsGit } from "react-icons/bs";
import { FaSass } from "react-icons/fa";

//social network icons
import { BsLinkedin, BsGithub } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { TiSocialInstagram } from "react-icons/ti";
/////projects icons
////project images
//react images
import pokemonAppReactImg from "../../../img/projects/pokemon_app_react.png";
import pokemonImgPhone from "../../../img/projects/phone/pokemon-phone.png";
import skockoImg from "../../../img/projects/skocko.png";
import skockoImgPhone from "../../../img/projects/phone/skocko-phone.png";
import cocktailsAppImg from "../../../img/projects/cocktailapp.png";
import cocktailsImgPhone from "../../../img/projects/phone/cocktailsapp-phone.png";
import colorGeneratorImg from "../../../img/projects/color-generator.png";
import colorGenImgPhone from "../../../img/projects/phone/color generator-phone.png";
//js images
import maptyImg from "../../../img/projects/mapty.png";
import bankistAppImg from "../../../img/projects/bankist_app.png";
import bankistWebsiteImg from "../../../img/projects/bankist_website.png";
import bankistImgPhone from "../../../img/projects/phone/baknist-phone.png";
import geographyAppImg from "../../../img/projects/geocountry.png";
import geographyImgPhone from "../../../img/projects/phone/geography-phone.png";
//other projects images
// import shoppingCartImg from '../../../img/projects/shopingchartapp.png';
// import shoppingCartImgPhone from '../../../img/projects/phone/shopingcarapp-phone.png';
// import stripeSubmenuImg from '../../../img/projects/stripe-submenu.png';
// import stripeImgPhone from '../../../img/projects/phone/stripe-phone.png';
// import sidebarModalImg from '../../../img/projects/modal-sidebar.png';
// import sidebarModalImgPhone from '../../../img/projects/phone/sidebar-modal-phone.png';
// import groceryListImg from '../../../img/projects/grocery-list.png';
// import groceryListImgPhone from '../../../img/projects/phone/grocery-list-phone.png';
// import textGeneratorImg from '../../../img/projects/random-text-generated.png';
// import randomTextGenImgPhone from '../../../img/projects/phone/random-text-gen-phone.png';
// import automatedReviewSliderImg from '../../../img/projects/review-slider.png';
// import reviewSliderImgPhone from '../../../img/projects/phone/reviews-slider-phone.png';
// import jobTabsImg from '../../../img/projects/jobs_app.png';
// import jobsAppImgPhone from '../../../img/projects/phone/jobsapp-phone.png';
// import menuAppImg from '../../../img/projects/menuapp.png';
// import menuImgPhone from '../../../img/projects/phone/restoraunt-menu-phone.png';
// import QandAImg from '../../../img/projects/questionsapp.png';
// import questionsImgPhone from '../../../img/projects/phone/questions-phone.png';
// import reviewCardsImg from '../../../img/projects/reviews_cards.png';
// import reviewCardImgPhone from '../../../img/projects/phone/review-card-phone.png';
// import toursAppImg from '../../../img/projects/tours_blocks.png';
// import toursImgPhone from '../../../img/projects/phone/tours-phone.png';
// import movieListImg from '../../../img/projects/movies.png';
// import moviesImgPhone from '../../../img/projects/phone/movies-phone.png';

// import diceGameImg from '../../../img/projects/dice_game.png';
// import diceGameImgPhone from '../../../img/projects/phone/dice-game-phone.png';
// import guesNumbGameImg from '../../../img/projects/guess_game.png';
// import numberGuessImgPhone from '../../../img/projects/phone/number-phone.png';

/////SIDEBAR
export const sidebarData = [
  {
    href: "about_me",
    icon: <IoMdFingerPrint className="side-nav__link--icon" />,
  },

  {
    href: "experience_education",
    icon: (
      <div className="custom-icon-container">
        <MdWorkHistory className="briefcase-icon side-nav__link--icon" />
        <GiGraduateCap className="graduation-icon" />
      </div>
    ),
  },

  { href: "projects", icon: <FaFileCode className="side-nav__link--icon" /> },
  { href: "contact", icon: <GrContact className="side-nav__link--icon" /> },
];

/////PROFILE

export const profileData = [
  {
    id: 1,
    icon: <TiSocialInstagram className="profile-link-icon" />,
    href: "https://www.instagram.com/kyriakos.94/",
  },
  {
    id: 2,
    icon: <BsLinkedin className="profile-link-icon" />,
    href: "https://www.linkedin.com/in/popovicnedeljko994/",
  },
  {
    id: 3,
    icon: <BsGithub className="profile-link-icon" />,
    href: "https://github.com/popovic-nedeljko",
  },
];

/////CARDS
//ABOUT ME CARD

//---info section
export const aboutMeInfo = [
  `I am a frontend developer with an economics background and a strong passion for technology and coding. My main areas of expertise include HTML, CSS, SASS, JavaScript, React, and Webpack. I am also outgoing and ambitious, with a strong desire for self-improvement, both personally and professionally. My main goal is to build top-class web applications and websites.`,
];

//---personality section
export const personalityData = [
  {
    id: 1,
    icon: <FaRegHandshake className="personality-icon" />,
    name: "Interpersonal skills",
  },
  {
    id: 2,
    icon: <GiPublicSpeaker className="personality-icon" />,
    name: "Public speaking",
  },
  {
    id: 3,
    icon: <GiLightBulb className="personality-icon" />,
    name: "Resourceful",
  },
  {
    id: 4,
    icon: <TiGroupOutline className="personality-icon" />,
    name: "Extrovert",
  },
  {
    id: 5,
    icon: <GiPencilBrush className="personality-icon" />,
    name: "Creative",
  },
  {
    id: 6,
    icon: <GiChameleonGlyph className="personality-icon" />,
    name: "Flexible",
  },
  {
    id: 7,
    icon: <MdOutlineRocketLaunch className="personality-icon" />,
    name: "Ambitious",
  },
  {
    id: 8,
    icon: <GiStairsGoal className="personality-icon" />,
    name: "Decisive",
  },
  {
    id: 9,
    icon: <GiBullseye className="personality-icon" />,
    name: "Direct",
  },
  {
    id: 10,
    icon: <MdOutlineManageAccounts className="personality-icon" />,
    name: "Self-Improvement",
  },
];

//---hobby section
export const hobbiesData = [
  {
    icon: <BsFillCpuFill className="hobbies-icon" />,
    name: "Technology",
  },
  {
    icon: <AiOutlineAreaChart className="hobbies-icon" />,
    name: "Economy",
  },
  {
    icon: <FaGlobe className="hobbies-icon" />,
    name: "(Geo)politics",
  },
  {
    icon: <MdPsychology className="hobbies-icon" />,
    name: "Psychology",
  },
  {
    icon: <FaBook className="hobbies-icon" />,
    name: "Books",
  },
  {
    icon: <GrGamepad className="hobbies-icon" />,
    name: "Games",
  },
  {
    icon: <BiMoviePlay className="hobbies-icon" />,
    name: "Movies & anime",
  },
  {
    icon: <GiBodyBalance className="hobbies-icon" />,
    name: "Calistenics",
  },
];

//EXPERIENCE AND EDUCATION CARD

//---info section
export const experienceInfo = [
  `Completed four Udemy courses HTML, CSS (SASS, Flexbox, Grid), JavaScript, and React, and built 10+ projects.
  Conducted a case study project on 'React vs. Vanilla JavaScript' as part of personal development.
  Skilled in implementing the Model-View-Controller (MVC) architecture in JavaScript and creating UML diagrams.
  Experienced in writing React components using context and hooks, APIs, and custom hooks.
  Demonstrated proficiency in task management, meeting deadlines, efficiency and market analysis, and getting  knowledge and insights by fostering a collaborative team environment during a three-year experience in a large corporation.`,

  //  For further details, please check below and Projects section.`,
];

//---skills section
export const skillsData = [
  {
    id: 1,
    name: "JavaScript",
    icon: <SiJavascript className="skills-icon js" />,
  },
  {
    id: 2,
    name: "React",
    icon: <SiReact className="skills-icon react" />,
  },
  {
    id: 3,

    name: "HTML5",
    icon: <SiHtml5 className="skills-icon html" />,
  },
  {
    id: 4,
    name: "CSS3",
    icon: <SiCss3 className="skills-icon css" />,
  },
  {
    id: 5,
    name: "TypeScript",
    icon: <SiTypescript className="skills-icon ts" />,
  },
  {
    id: 6,
    name: "NodeJS",
    icon: <SiNodedotjs className="skills-icon node" />,
  },
  {
    id: 7,
    name: "Sass",
    icon: <FaSass className="skills-icon sass" />,
  },
  {
    id: 8,
    name: "Git",
    icon: <BsGit className="skills-icon git" />,
  },
  {
    id: 9,
    name: "SQL",
    icon: <TbSql className="skills-icon sql" />,
  },
  {
    id: 10,
    name: "SAP",
    icon: <SiSap className="skills-icon sap" />,
  },
  {
    id: 11,
    name: "Excel",
    icon: <SiMicrosoftexcel className="skills-icon exl" />,
  },
];

//---ecducation AND experience section
const educationData = [
  {
    id: 1,
    title: "Udemy course - Frontend development",
    date: "Dec 2021 - Feb 2023",
    city: "Belgrade, Serbia",
    info: "HTML, CSS, JavaScript, React",
  },
  {
    id: 2,
    title: "Efficiency course",
    place: "Mokra Gora School of Management",
    date: "Apr 2020",
    city: "Belgrade, Serbia",
    info: "Time management and organization of projects and tasks",
  },
  {
    id: 3,
    title: "MC Office - Excel  course",
    place: "University of Belgrade, Faculty of Economics",
    date: "Mar 2018 - May 2019",
    city: "Belgrade, Serbia",
    info: "Advanced knowlage of MC Office and finance and analysis using Excel tools",
  },
  {
    id: 4,
    title: `Bachelor's Degree`,
    place: "University of Belgrade, Faculty of Economics",
    date: "Sep 2013 - Sep 2018",
    city: "Belgrade, Serbia",
    info: "International Economics and Foreign Trade",
  },
  {
    id: 5,
    title: "Economic-trade school",
    date: "Sep 2009- Jun 2013",
    city: "Bor, Serbia",
    info: "Finance administrator- experimental modul",
  },
];

const experienceData = [
  {
    id: 11,
    title: "PlanIt",
    city: "Belgrade, Serbia",
    date: "Dec 2023 - present",
    info: "Frontend Software Engineer",
  },
  {
    id: 1,
    title: "NIS Gazprom Neft",
    city: "Belgrade, Serbia",
    date: "Nov 2020 - May 2022",
    info: "Coordinator for Procurement of Material and Equipment for Rafinery",
  },
  {
    id: 2,
    title: "NIS Gazprom Neft",
    city: "Belgrade, Serbia",
    date: "Mar 2020 - Oct 2020",
    info: "Expert Associate for Procurement of Material and Equipment for Rafinery",
  },
  {
    id: 3,
    title: "NIS Gazprom Neft",
    city: "Belgrade, Serbia",
    date: "Nov 2019 - Feb 2020",
    info: "Intern at Procurement Department",
  },
  {
    id: 4,
    title: "Fashion Company",
    city: "Belgrade, Serbia",
    date: "Sep 2019 - Oct 2019",
    info: "Finance Intern",
  },
  {
    id: 5,
    title: `Virtual Ltd. 'Black&White'`,
    city: "Bor, Serbia",
    date: "Sep 2009 - May 2013",
    info: `Financial Management; Accounting; Marketing; Sales and Purchasing;
    Legal Foundation and legal liquidation of Virtual Ltd. “Black&White; Gathering, managing and
    investing virtual funds; Financial projections; Bookkeeping; Managing balance sheet, profit
    and loss sheet, cash flow sheet; Cost analysis; Market research; Swot analysis; Catalogs;`,
  },
];

export const componentBlockData = [
  {
    id: 1,
    classDiv: "exp-edu--container_block",
    classHeader: "exp-edu--container_block-header",
    title: "education",

    iconHeader: (
      <IoIosSchool className="exp-edu--container_block-header--icon" />
    ),
    data: educationData,
  },
  {
    id: 2,
    classDiv: "exp-edu--container_block",
    classHeader: "exp-edu--container_block-header",
    title: "experience",

    iconHeader: (
      <RiBriefcase4Line className="exp-edu--container_block-header--icon" />
    ),
    data: experienceData,
  },
];
//PROJECT CARD

export const reactProjectsData = [
  {
    id: 1,
    code: "React & JS",
    href: "projects/pokemon",
    title: (
      <>
        React <span>vs</span> JavaScript
      </>
    ),
    subtitle: "A Case Study of Pokemon Application",
    subtitleList: "Case study",
    tags: "pokemon application",
    images: [
      {
        img: pokemonAppReactImg,
        imgPhone: pokemonImgPhone,
      },
    ],
    link: [
      { text: "React app", url: "https://pokemon-app-reactapp.netlify.app/" },
      { text: "JavaSript app", url: "https://pokemonapp-2022.netlify.app/" },
    ],
    git: [
      {
        text: <SiReact className="icon-link" />,
        link: "https://github.com/popovic-nedeljko/Pokemon-application-ReactJS",
      },
      {
        text: <SiJavascript className="icon-link" />,
        link: "https://github.com/popovic-nedeljko/PokemonApp-JS",
      },
    ],
    info: [
      `The Pokemon application is an engaging  application designed  for fans of the Pokemons. With a sleek and intuitive user interface, users can easily browse and explore over 1000 pokemons, check their detailed stats, and save their favorite ones. With its cool and user-friendly design, the Pokemon application provides a fun and enjoyable experience for users of all ages `,
      "This app was built using both JavaScript and React to compare the two programming languages.",

      "JavaScript OVERVIEW:",
      "The JavaScript code utilizes OOP programming with MVC architecture.",
      "OOP programming enables better code encapsulation and facilitates inheritance and polymorphism for easier code maintenance, also it is more familiar to developers with experience in Java or C++..",

      "React OVERVIEW:",
      "In contrast, the React code employs functional programming.",
      "React leads to better code organization and reduces bugs caused by side-effects.",
      "Functional programming has a simpler syntax and is more concise, making it easier to read and write, and it allows for better code reusability through easy component composition and reuse. Also enables better performance optimization through easier memoization and caching.",
      "React is not SEO friendly.",

      `In conclusion, the choice between functional and OOP programming depends on the project's requirements.`,
      "Ultimately, the right choice of programming approach depends on the needs and goals of the project.",
    ],
  },
  {
    id: 2,
    code: "React",
    href: "projects/skocko",
    title: "Skočko game",
    subtitle: "Challenge your logic and play",
    subtitleList: "game application",
    tags: "powered by react",
    images: [
      {
        img: skockoImg,
        imgPhone: skockoImgPhone,
      },
    ],
    link: [{ text: "Play", url: "https://skocko-game-2023.netlify.app/" }],
    git: [
      {
        text: "code",
        link: "https://github.com/popovic-nedeljko/Skocko-Game-ReactJS",
      },
    ],
    info: [
      "The Skocko game is an entertaining and challenging game that emulates the game found in the popular Slagalica quiz. Players are given a limited amount of time to guess the correct combination of symbols in the code.",

      "The game is structured using module components and utilizes React hooks. AppProvider and AppContext are providing data to child components through the useContext hook. With the integration of an asynchronous timer, logical algorithms, and SCSS styling, the Skocko game offers a seamless and user-friendly experience to its players.",
    ],
  },
  {
    id: 3,
    code: "React",
    href: "projects/cocktails",
    title: "the Cocktail",
    subtitle: "Find Your Perfect Cocktail Recipe",
    subtitleList: "application",
    tags: "react course",
    images: [
      {
        img: cocktailsAppImg,
        imgPhone: cocktailsImgPhone,
      },
    ],
    link: [
      {
        text: "Explore app",
        url: "https://cocktail-application-2022.netlify.app/",
      },
    ],
    git: [
      {
        text: "code",
        link: "https://github.com/popovic-nedeljko/Cocktails-API-router-ReactJS",
      },
    ],
    info: [
      "A cocktail recipe search engine that allows users to search for their favorite drinks and get a list of cocktail recipes that match their search. The purpose of the application is to provide a user-friendly interface for cocktail enthusiasts to find new and exciting drinks to try.",

      "By utilizing the API provided by TheCocktailDB, the application offers a vast library of cocktail recipes, including the ingredients required and the instructions to make them.",

      "The app is built using a component-based structure with state managed by a custom context provider using the useContext hook. Data is fetched asynchronously using the fetch API from TheCocktailDB API and stored in a simplified object structure in state for easy access. React Router handles page navigation and error handling displays a user-friendly message if API requests fail.",
    ],
  },
  // {
  //   id: 4,
  //   code: 'React',
  //   href: 'projects/shopping-cart',

  //   title: 'shopping Cart',
  //   subtitle:
  //     'Effortless Shopping: Manage Your Cart and Track Your Costs with Ease',
  //   subtitleList: 'mini application',
  //   tags: 'react course',
  //   images: [
  //     {
  //       img: shoppingCartImg,
  //       imgPhone: shoppingCartImgPhone,
  //     },
  //   ],
  //   link: [
  //     {
  //       text: 'Access app',
  //       url: 'https://shoping-chart-app-2022.netlify.app/',
  //     },
  //   ],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/Shoping-Cart-ReactJS',
  //     },
  //   ],
  //   info: [
  //     'Application allows users to add items to their cart, update the cart items, and view the total cost of their purchase.',
  //     'The shopping cart application makes use of React hooks and a global state management system with the context API.',
  //     `Reducer function is used for handling actions such as clearing cart, removing items, increasing or decreasing the amount of items in the cart, displaying items in the cart, and calculating the total cost of items in the cart
  //     Uses React hooks are used state management and side effects handling.`,
  //   ],
  // },
  // {
  //   id: 5,
  //   code: 'React',
  //   href: 'projects/stripe-submenu',
  //   title: 'Stripe',
  //   subtitle: 'Stripe-like payments infrastructure with enhanced submenus',
  //   subtitleList: 'submenu component',
  //   tags: 'react course',
  //   images: [
  //     {
  //       img: stripeSubmenuImg,
  //       imgPhone: stripeImgPhone,
  //     },
  //   ],
  //   link: [
  //     {
  //       text: 'Check the app',
  //       url: 'https://stripe-submenu-project2022.netlify.app/',
  //     },
  //   ],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/Stripe-submenu-ReactJS',
  //     },
  //   ],
  //   info: [
  //     'A mockup React-based web application designed to mimic the functionality of the real Stripe web application which is  designed to provide a payments infrastructure for companies and manage their businesses online.',
  //     'Goal of this project is the submenu funcionality.',
  //     'The app uses React components like Navbar, Hero, Sidebar, and Submenu for a user-friendly interface. AppContext and AppProvider handle data; AppProvider creates a context holding app state and update methods. useGlobalContext accesses state and methods. Data is stored in data.js as an array of objects with submenu links, imported with import statements. useState and useEffect hooks handle state and lifecycle events.',
  //   ],
  // },
  // {
  //   id: 6,
  //   code: 'React',
  //   href: 'projects/sidebar-modal',
  //   title: 'Sidebar and Modal',
  //   subtitle: 'Smoothly integrate modals and sidebar functionality in your app',
  //   subtitleList: 'app components',
  //   tags: 'react course',
  //   images: [
  //     {
  //       img: sidebarModalImg,
  //       imgPhone: sidebarModalImgPhone,
  //     },
  //   ],
  //   link: [{ text: 'Use now', url: 'https://modal-sidebar-2022.netlify.app/' }],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/Sidebar-modal-components-ReactJS',
  //     },
  //   ],
  //   info: [
  //     'React hooks such as useState and useContext are utilized to manage state effectively. The AppProvider component plays a crucial role in providing the AppContext and making its values available to its child components using the useContext hook,therefore removing the need for complex props drilling.',
  //   ],
  // },
  // {
  //   id: 7,
  //   code: 'React',
  //   href: 'projects/grocery-list',
  //   title: 'Grocery list',
  //   subtitle: 'Stay Organized and Never Miss an Item',
  //   subtitleList: 'mini application',
  //   tags: 'react course',
  //   images: [
  //     {
  //       img: groceryListImg,
  //       imgPhone: groceryListImgPhone,
  //     },
  //   ],
  //   link: [
  //     { text: 'Launch app', url: 'https://grocery-list-app-2022.netlify.app/' },
  //   ],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/Grocery-list-ReactJS',
  //     },
  //   ],
  //   info: [
  //     'This application  provides a user-friendly interface for managing a grocery list, allowing users to add, edit, and remove items from the list with ease. The application also features an alert system to notify users of successful or unsuccessful operations.',

  //     'React hooks are used to manage the state of the application, and localStorage is used to persist the data between sessions. The code also includes utility functions to handle features such as adding, editing, and removing items from the list, as well as displaying alerts to the user. ',
  //   ],
  // },
  {
    id: 8,
    code: "React",
    href: "projects/color-generator",
    title: "Color generator",
    subtitle: "Effortlessly Generate Color Palettes for Your Projects",
    subtitleList: "application",
    tags: "react course",
    images: [
      {
        img: colorGeneratorImg,
        imgPhone: colorGenImgPhone,
      },
    ],
    link: [
      {
        text: "Get your color",
        url: "https://color-generator2022.netlify.app/",
      },
    ],
    git: [
      {
        text: "code",
        link: "https://github.com/popovic-nedeljko/Color-generator-ReactJS",
      },
    ],
    info: [
      `The React application allows users to generate color palettes based on an input color, providing a simple and customizable tool for web design and development.`,
      `The application takes an input color, converts it into different shades and tints using the 'Values' package, and displays the resulting color palette as a list of colored squares with their corresponding hex values and percentage weights.`,
      `Users can also copy the hex value of each color to their clipboard by clicking on the color square.`,
      ` The React hooks, external package, and utility functions utilized in the application contribute to its smooth functionality and user-friendly interface.`,
    ],
  },
  // {
  //   id: 9,
  //   code: 'React',
  //   href: 'projects/text-generator',
  //   title: 'Random text generator',
  //   subtitle: 'Create dummy text for your web projects, in seconds',
  //   subtitleList: 'application',
  //   tags: 'react course',
  //   images: [
  //     {
  //       img: textGeneratorImg,
  //       imgPhone: randomTextGenImgPhone,
  //     },
  //   ],
  //   link: [
  //     {
  //       text: 'Launch app',
  //       url: 'https://lorem-ipsum-generator2022.netlify.app/',
  //     },
  //   ],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/LoremIpsum-GeneratorText-ReactJS',
  //     },
  //   ],
  //   info: [
  //     'React application which provides a simple and customizable tool for generating paragraphs of placeholder text for use in web design and development.',
  //     'With the ability to specify the number of paragraphs needed, users can quickly generate text that is ideal for filling in mockups or testing page layouts.',
  //   ],
  // },
  // {
  //   id: 10,
  //   code: 'React',
  //   href: 'projects/review-slider-component',
  //   title: 'Review slider',
  //   subtitle:
  //     'Engage with Customer Feedback with our customizable React review slider',
  //   subtitleList: 'app component',
  //   tags: 'react course',
  //   images: [
  //     {
  //       img: automatedReviewSliderImg,
  //       imgPhone: reviewSliderImgPhone,
  //     },
  //   ],
  //   link: [
  //     { text: 'Open now', url: 'https://review-slider-2022.netlify.app/' },
  //   ],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/Automated-Review-slider-ReactJS',
  //     },
  //   ],
  //   info: [
  //     'React-based review slider that allows users to view customer reviews and testimonials through a dynamic slider interface.',

  //     'The slider is controlled through React hooks like useState and useEffect, which enable the management of state and the fetching of data from an external data source.',

  //     'The use of conditional rendering and the key prop on job items ensures efficient rendering of items in the list, while the integration of external icon libraries adds visual appeal to the slider interface. ',
  //     '',
  //   ],
  // },
  // {
  //   id: 11,
  //   code: 'React',
  //   href: 'projects/jobs-tabs-component',
  //   title: 'Job tabs component',
  //   subtitle: 'Discover Job Opportunities with a Click',
  //   subtitleList: 'app component',
  //   tags: 'react course',
  //   images: [
  //     {
  //       img: jobTabsImg,
  //       imgPhone: jobsAppImgPhone,
  //     },
  //   ],
  //   link: [{ text: 'Go to site', url: 'https://jobtabsapp.netlify.app/' }],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/Job-experiance-tabs-ReactJS',
  //     },
  //   ],
  //   info: [
  //     'Job listing website that displays job titles and descriptions from an external API. It allows users to view job details for multiple companies and navigate through different job listings by clicking on company names. The application is efficient and user-friendly, with fast loading times and easy-to-use navigation.',

  //     'React and its hooks, such as useState and useEffect, simplify state management and API data fetching. The code also implements conditional rendering to display a loading state and render job information based on user selection. Additionally, the use of a key prop on job items ensures efficient rendering of items in the list.',
  //   ],
  // },
  // {
  //   id: 12,
  //   code: 'React',
  //   href: 'projects/restoraunt-menu',
  //   title: 'Restoraunt menu',
  //   subtitle:
  //     'Dynamic menu: the key to an enhanced restaurant website experience',
  //   subtitleList: 'mini website',
  //   tags: 'react course',
  //   images: [
  //     {
  //       img: menuAppImg,
  //       imgPhone: menuImgPhone,
  //     },
  //   ],
  //   link: [{ text: 'Visit now', url: 'https://menu-app-2022.netlify.app/' }],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/Menu-app-ReactJS',
  //     },
  //   ],
  //   info: [
  //     'The dynamic menu created using React provides a seamless user experience for customers visiting a restaurant website. By allowing users to filter menu items by category, the application improves the navigation of the website and increases the likelihood of users finding items that meet their preferences. ',

  //     'The usage of React hooks, particularly useState, significantly enhances the management of state within the codebase, promoting readability and maintainability. The implementation of functional components increases modularity and simplifies future modifications. The application is responsive, efficiently renders menu items and swiftly navigates between categories, resulting in a responsive user experience.',
  //   ],
  // },
  // {
  //   id: 13,
  //   code: 'React',
  //   href: 'projects/q-and-a',
  //   title: 'Q and A',
  //   subtitle: 'Log in with ease: A interactive guide for login information',
  //   subtitleList: 'app component',
  //   tags: 'react course',
  //   images: [
  //     {
  //       img: QandAImg,
  //       imgPhone: questionsImgPhone,
  //     },
  //   ],
  //   link: [
  //     {
  //       text: 'Go to site',
  //       url: 'https://login-questions-and-answers.netlify.app/',
  //     },
  //   ],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/QandA-component-ReactJS',
  //     },
  //   ],
  //   info: [
  //     'User-friendly and interactive way to provide information to users about logging in. By presenting the information in a question-and-answer format, the application allows users to easily find and understand the information they need.',

  //     'The use of icons to expand and collapse the answers adds an intuitive element to the user interface, making the application accessible and engaging.',

  //     `The application uses a functional component for the SingleQuestion component and employs props to pass data between components.The useState hook provides a straightforward way to manage the application's state, while the use of the map function enables the DRY principle and readable code `,
  //   ],
  // },
  // {
  //   id: 14,
  //   code: 'React',
  //   href: 'projects/review-cards-component',
  //   title: 'Review cards',
  //   subtitle: 'Showcase customer reviews with style',
  //   subtitleList: 'app component',
  //   tags: 'react course',
  //   images: [
  //     {
  //       img: reviewCardsImg,
  //       imgPhone: reviewCardImgPhone,
  //     },
  //   ],
  //   link: [
  //     {
  //       text: 'Enter the review',
  //       url: 'https://reviews-cards-2022.netlify.app/',
  //     },
  //   ],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/Review-cards-ReactJS',
  //     },
  //   ],
  //   info: [
  //     'React component-application designed to provide an interactive and dynamic user experience for displaying customer reviews.',

  //     'The use of React hooks allows for easy management of state and updating the UI based on user actions.',

  //     'The FontAwesome icon library provides a simple way to add icons to the UI, enhancing the overall design and usability of the application.',
  //   ],
  // },
  // {
  //   id: 15,
  //   code: 'React',
  //   href: 'projects/tours',
  //   title: 'Tours',
  //   subtitle: 'Explore Tours Around The World With A Single Click',
  //   subtitleList: 'mini website',
  //   tags: 'react component ',
  //   images: [
  //     {
  //       img: toursAppImg,
  //       imgPhone: toursImgPhone,
  //     },
  //   ],
  //   link: [
  //     { text: 'Access website', url: 'https://tours-app-2022.netlify.app/' },
  //   ],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/Tours-App-ReactJS',
  //     },
  //   ],
  //   info: [
  //     'React mini application that fetches a list of tours from a third-party API and displays them to the user. The user can remove tours from the list if not interested,as well as expand and collapse text if interested ',

  //     `React hooks like useState and useEffect are used to manage the application's state and lifecycle. It also showcases how to fetch data from an external API using async/await syntax and how to pass functions down to child components as props to handle user actions.`,

  //     'This mini app is a good example of how React can be used to create a simple but effective application that interacts with an external API to provide dynamic content to the user.',
  //   ],
  // },
  // {
  //   id: 16,
  //   code: 'React',
  //   href: 'projects/movie-list',
  //   title: 'Movies List',
  //   subtitle: 'Get your popcorn ready: Browse and discover your next movie',
  //   subtitleList: 'mini application',
  //   tags: 'react course',
  //   images: [
  //     {
  //       img: movieListImg,
  //       imgPhone: moviesImgPhone,
  //     },
  //   ],
  //   link: [{ text: 'Visit now', url: 'https://movie-list2022.netlify.app/' }],
  //   git: [
  //     { text: 'code', link: 'https://github.com/popovic-nedeljko/Movie-List' },
  //   ],
  //   info: [
  //     'Simple React application that renders a list of movies with their name, year, and image.',

  //     'The code demonstrates the simplicity and flexibility of React by using functional components and props to render a dynamic list of movies. It also showcases the benefits of functional programming in React, which allows for cleaner and more readable code. Overall, the code presents a good example of how React can be used to create simple and effective applications.',
  //   ],
  // },
];
export const javaScriptProjectsData = [
  {
    id: 1,
    code: "JavaScript",
    href: "projects/mapty",
    title: "Mapty workout",
    subtitle: "Track Your Way to Fitness",
    subtitleList: "application",
    tags: "JavaScript course",
    images: [
      {
        img: maptyImg,
      },
    ],
    link: [
      {
        text: "Go to Mapty",
        url: "https://mapty-yourworkoutonmap.netlify.app/",
      },
    ],
    git: [
      {
        text: "code",
        link: "https://github.com/popovic-nedeljko/MaptyApp-map-your-workouts",
      },
    ],
    info: [
      "This fitness app enables users to track their running and cycling workouts by recording the distance, duration, and location of their activities.",

      "This app is made by using object-oriented programming, which allows for more efficient and organized code by encapsulating related variables and functions into objects.",

      "The classes provide a framework for creating and managing workout objects with properties such as date, location, distance, and duration.",

      "The App class uses the Leaflet library to render a map, and implements methods for adding, deleting, and displaying workout objects on the map by using DOM manimulation. Additionally, it allows users to toggle the display of elevation and cadence input fields, and saves data to local storage for persistent use.",
    ],
  },
  {
    id: 2,
    code: "JavaScript",
    href: "projects/bankist",
    title: "Bankist",
    subtitle: "Experience modern banking",
    subtitleList: "website",
    tags: "JavaScript course",
    images: [
      {
        img: bankistWebsiteImg,
        imgPhone: bankistImgPhone,
      },
    ],
    link: [
      {
        text: "Access website",
        url: "https://bankist-bankswebsite.netlify.app/",
      },
    ],
    git: [
      {
        text: "code",
        link: "https://github.com/popovic-nedeljko/Bankist-banks-website-JS",
      },
    ],
    info: [
      "This banking website utilizes modern web development techniques to provide users with an engaging and interactive experience, including modal windows, scrolling animations, and tabbed components.",

      "The code for this website employs various event listeners and DOM manipulation techniques to create dynamic features such as sticky navigation, lazy loading images, and a slider component.",

      "By using techniques such as lazy loading and intersection observers, this website prioritizes performance and optimizes the loading of assets to provide users with a smooth and fast experience.",

      "The combination of creative design and thoughtful implementation in this website creates a cohesive and engaging user experience, from the carefully crafted animations to the well-structured navigation and content layout.",
    ],
  },
  {
    id: 3,
    code: "JavaScript",
    href: "projects/bankist-account",
    title: "Bankist account",
    subtitle: "Manage your finances like a pro",
    subtitleList: "application",
    tags: "JavaScript course ",
    images: [
      {
        img: bankistAppImg,
      },
    ],
    link: [
      {
        text: "Explore app",
        url: "https://bankist-bankaccountapp.netlify.app/",
      },
    ],
    git: [
      {
        text: "code",
        link: "https://github.com/popovic-nedeljko/Bankist--bank-account-app",
      },
    ],
    info: [
      "This application with its intuitive user interface and responsive design allows users to manage their accounts, check their balances, and perform various transactions such as transfers, loans, and closures.",

      `By keeping track of the transaction history and interest rates for each account, this application can generate comprehensive summaries of the user's financial activities, making it easy to monitor spending and track progress toward financial goals.`,

      "This application was created with JavaScript, making use of a range of advanced techniques such as DOM manipulation, high order functions, arrow functions,ternary operators and array methods. These features enabled the application to easily update the values of account information labels in real-time, while also providing the ability to perform essential operations like login, transfer, loan requests, and account closing with ease.",
    ],
  },
  {
    id: 4,
    code: "JavaScript",
    href: "projects/geography",
    title: "Geography",
    subtitle: "Explore the world",
    subtitleList: "mini application",
    tags: "JavaScript course",
    images: [
      {
        img: geographyAppImg,
        imgPhone: geographyImgPhone,
      },
    ],
    link: [{ text: "Open now", url: "https://geographyapp.netlify.app/" }],
    git: [
      {
        text: "code",
        link: "https://github.com/popovic-nedeljko/GeographyApp-JS",
      },
    ],
    info: [
      "This code implements functionality for searching and displaying country data through API calls and DOM manipulation.",

      "A high level of error handling is implemented to ensure that the user receives feedback in case of incorrect input or other errors.",

      "  The code also includes functionality to display information on neighboring countries based on the selected country.",

      "Additionally, it allows the user to retrieve and display information about their current location through the use of the geolocation API.",
    ],
  },
  // {
  //   id: 5,
  //   code: 'JavaScript',
  //   href: 'projects/dice-game',
  //   title: 'Dice game',
  //   subtitle: 'Roll the dice and test your luck',
  //   subtitleList: 'application',
  //   tags: 'JavaScript course',
  //   images: [
  //     {
  //       img: diceGameImg,
  //       imgPhone: diceGameImgPhone,
  //     },
  //   ],
  //   link: [
  //     { text: 'Play now', url: 'https://pig-game-dicegames.netlify.app/' },
  //   ],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/Pig-game--dice-game-app-JS',
  //     },
  //   ],
  //   info: [
  //     "The Dice game's user interface was built using JavaScript and DOM manipulation, allowing for dynamic updating of scores and game status as players roll the dice and accumulate points.",

  //     'Through the use of conditional statements, loops, and event listeners, the dice game provides an engaging and interactive experience for two players, with the ability to customize the winning score and start a new game with the click of a button.',

  //     'The use of a random number generator simulates rolling dice in the game, with a 6-6 roll resulting in the loss of all accumulated points for the active player.',

  //     'The remaining features include logic for switching players, displaying scores, and determining a winner based on a chosen score threshold.',
  //   ],
  // },
  // {
  //   id: 6,
  //   code: 'JavaScript',
  //   href: 'projects/guess-number-game',
  //   title: 'Guess the number Game',
  //   subtitle: 'Put Your Guessing Skills to the Test',
  //   subtitleList: 'application',
  //   tags: 'JavaScript course',
  //   images: [
  //     {
  //       img: guesNumbGameImg,
  //       imgPhone: numberGuessImgPhone,
  //     },
  //   ],
  //   link: [
  //     {
  //       text: 'Visit now & play',
  //       url: 'https://guess-my-number-simple-app.netlify.app/',
  //     },
  //   ],
  //   git: [
  //     {
  //       text: 'code',
  //       link: 'https://github.com/popovic-nedeljko/Guess-my-number-simple-app',
  //     },
  //   ],
  //   info: [
  //     'Mini application game.',

  //     'A responsive user interface for a number guessing game was developed using JavaScript.',

  //     `The game's functionality was implemented using fundamental JavaScript concepts such as addEventListeners, reset logic, else-if statements, truthy and falsy values, the equality operator, string and template literals and DOM manipulation`,

  //     'The implementation of a highscore feature involved the use of conditional statements, type conversion and coercion, and a random number generator to create a challenging gameplay experience.',

  //     'The messaging system in the game was made responsive using the ternary operator, boolean logic, and template literals, providing users with instant feedback and increasing user engagement. ',

  //     ' Arrays were used to store and manipulate game data, enabling the creation of features like a highscore leaderboard and a reset button for a more immersive gaming experience.',
  //   ],
  // },
];

//project container (react+javaScript data)
export const projectContainerData = [
  {
    id: 1,
    name: "React",

    icon: <SiReact className="projects-container-header-icon icons-react" />,

    projectData: reactProjectsData,
  },
  {
    id: 2,
    name: "JavaScript",

    icon: (
      <SiJavascript className="projects-container-header-icon icons-javaScript" />
    ),
    projectData: javaScriptProjectsData,
  },
];

//CONTACT CARD

//--- socialNetwork section
export const socialNetData = [
  {
    name: "instagram",
    link: "https://www.instagram.com/kyriakos.94/",
    icon: (
      <AiOutlineInstagram className="contact-social--icon contact-social--icon_ig" />
    ),
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/popovicnedeljko994/",
    icon: (
      <BsLinkedin className="contact-social--icon contact-social--icon_ln" />
    ),
  },
  {
    name: "GitHub",
    link: "https://github.com/popovic-nedeljko",
    icon: (
      <BsGithub className="contact-social--icon contact-social--icon_git" />
    ),
  },
];
export default componentBlockData;
