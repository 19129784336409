import React from "react";
import "./SidebarComponent.scss";
import { useGlobalContext } from "../../context";
import { Link, useLocation } from "react-router-dom";

function SidebarComponent({ href, card, icon }) {
  const { setActiveCard, activeCard, setShowContent, start, setStart } =
    useGlobalContext();

  const { pathname } = useLocation();
  const cardActive = pathname.slice(1);

  return (
    <li
      className={`side-nav__item ${
        cardActive === href ? "side-nav__item--active" : ""
      }`}
      onClick={() => {
        setActiveCard(href);
        if (activeCard === href || cardActive === href) {
          return;
        } else setShowContent(false);
        if (start) setStart(false);
      }}
    >
      <Link to={href} className="side-nav__link">
        {icon}
        <span>{card}</span>
      </Link>
    </li>
  );
}

export default SidebarComponent;
