import React, { useContext, useEffect, useState } from 'react';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [activeCard, setActiveCard] = useState('');
  const [showContent, setShowContent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [start, setStart] = useState(true);

  useEffect(() => {
    const showTimer = setTimeout(() => {
      setShowContent(true);
    }, 550);

    return () => {
      clearTimeout(showTimer);
    };
  }, [activeCard]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    // Change the value to adjust the loading time

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <AppContext.Provider
      value={{
        start,
        setStart,
        activeCard,
        setActiveCard,
        showContent,
        setShowContent,
        isLoading,
        openInNewTab,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
