import './ButtonLink.scss';
import React from 'react';

function ButtonLink({ text, icon, click }) {
  return (
    <button className='btn contact-btn' onClick={click}>
      <span className='btn__visible'>{text}</span>
      <span className='btn__invisible'>{icon}</span>
    </button>
  );
}

export default ButtonLink;
