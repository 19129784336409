import './ProjectComponent.scss';
import { Link } from 'react-router-dom';
import React from 'react';
import { useGlobalContext } from '../../../../context';

function ProjectComponent({ img, title, subtitle, tags, href }) {
  const { setActiveCard, setShowContent, start, setStart } = useGlobalContext();

  return (
    <div className='project-component'>
      <Link
        to={`/${href}`}
        onClick={() => {
          setActiveCard(href);
          setShowContent(false);
          if (start) setStart(false);
        }}
      >
        <div className='project-component-picture'>
          <img className='project-component-picture--img' src={img} alt='pic' />
        </div>
        <div className='project-component-info_container'>
          <div className='project-component-info'>
            <h4 className='project-component-info--title'>
              {title}
              <span>.</span>
            </h4>
            <div className='project-component-info--subtitle'>{subtitle}</div>
            <div className='project-component-info--tags'>{tags}</div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ProjectComponent;
