import React from 'react';
import ProjectComponent from './ProjectComponent';
import './ProjectComponentBlock.scss';

function ProjectComponentBlock({ projectData }) {
  return (
    <div className='project-block'>
      {projectData.map((project) => (
        <ProjectComponent
          key={project.id}
          img={project.images[0].img}
          title={project.title}
          subtitle={project.subtitleList}
          tags={project.tags}
          href={project.href}
        />
      ))}
    </div>
  );
}

export default ProjectComponentBlock;
