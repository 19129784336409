import React from 'react';
import './Contact.scss';
import { useGlobalContext } from '../../../context';
import MyMap from './Map/Map';
import Info from './Info/Info';
import SocialNetworks from './Social-networks/SocialNetworks';

function Contact() {
  const { showContent, start } = useGlobalContext();

  return (
    <div
      className={`card
    ${start ? 'card--active-start' : 'card--active'}
    `}
    >
      <div
        className='contact'
        style={{ display: showContent ? 'flex' : 'none' }}
      >
        <h1 className='contact-header'>
          contact<span>.</span>
        </h1>
        <div className='contact-message'>
          If you are looking for young and ambitious Frontend Devoloper, please
          do not hesitate to contact me.
        </div>
        <MyMap />
        <div className='contact-container'>
          <Info />

          <SocialNetworks />
        </div>
      </div>
    </div>
  );
}

export default Contact;
