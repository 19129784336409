import React from 'react';
import './Languages.scss';
import SkillComponent from '../Skills/SkillComponent';
import { MdSpatialAudioOff } from 'react-icons/md';

function Languages() {
  return (
    <div className='languages'>
      <h3 className='languages-header'>
        <MdSpatialAudioOff className='languages-header-icon' />
        languages
        <span>.</span>
      </h3>
      <ul className='languages-container'>
        <li className='languages-container-info'>
          <span>Serbian - </span>
          Native
        </li>
        <li className='languages-container-info'>
          <span>English - </span>
          Full Professional Proficiency
        </li>
      </ul>
    </div>
  );
}

export default Languages;
